<template>
  <div>
    <!--
    <br />
    <h2 class="page-title overline ml-4" dark>
      <v-icon color="green" class="mt-n1 mr-n2"
        >mdi-drag-vertical-variant</v-icon
      >
      {{ title }}
    </h2>-->
    <v-breadcrumbs :items="menu">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: ["title", "menu"],
};
</script>
<style scoped>
.page-title {
  font-weight: bolder;
  font-size: 16px !important;
}
</style>
