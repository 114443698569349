/* eslint-disable */
import {
  Bar,
  mixins,
  HorizontalBar
} from 'vue-chartjs';
const {
  reactiveProp
} = mixins

export default {
  extends: HorizontalBar,
  mixins: [reactiveProp],
  props: ['chartData', 'options', 'plugins'],
  mounted() {
    this.renderChart(this.chartData, this.options, this.plugins)
  }
}/** in render chart this.addPlugin({
  id: 'image-label',
  afterDraw: (chart) => {      
      var ctx = chart.chart.ctx; 
      var xAxis = chart.scales['x-axis-0'];
      var yAxis = chart.scales['y-axis-0'];
      xAxis.ticks.forEach((value, index) => {  
        var x = xAxis.getPixelForTick(index);      
        var image = new Image();
        image.src = images[index],
        ctx.drawImage(image, x - 12, yAxis.bottom + 10);
      });      
    }
}) */