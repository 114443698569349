<!-- eslint-disable -->
<template>
  <v-container id="dashboard" fluid tag="section" v-if="hasData">
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
      <div>
        <v-row>
          <v-col cols="5" md="4">
            <v-text-field
              label="Data Inicial"
              v-model="startDate"
              type="date"
              @change="changeAll()"
            ></v-text-field>
          </v-col>
          <v-col cols="5" md="4" class="ml-8">
            <v-text-field
              label="Data Final"
              v-model="endDate"
              type="date"
              @change="changeAll()"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-row>

    <v-row>
      <v-col cols="12">
        <template>
          <v-card color="dark" v-if="hasData" class="dash-card">
            <v-card-title class="overline">Top Five</v-card-title>
            <v-tooltip
              :nudge-top="-45"
              top
              v-for="(chart, index) in movements"
              :key="index"
              color="black"
            >
              <template v-slot:activator="{ on }" v-slot[item="item" ]>
                <v-row align="center">
                  <v-card
                    class="pl-4 ma-1 d-flex align-center"
                    :color="chart.memberGender === 'F' ? '#D81B60' : '#1976D2'"
                    :width="((widthScreen * 0.85) / movements[0].points) * chart.points"
                    elevation="0"
                    v-on="on"
                    :height="heightChartLine"
                  >
                    <v-avatar
                      style="border-radius: 25px"
                      :size="heightChartLine > 60 ? '55' : heightChartLine - 10"
                    >
                      <v-card color="white">
                        <v-img v-if="chart.memberPhoto" :src="chart.memberPhoto" />
                        <v-card
                          class="pa-2"
                          v-else
                          :height="heightChartLine > 60 ? '55' : heightChartLine - 10"
                          :width="heightChartLine > 60 ? '55' : heightChartLine - 10"
                        >
                          <span class="text-h6">{{ displayName(chart.memberName) }}</span>
                        </v-card>
                      </v-card>
                    </v-avatar>
                    <v-card-subtitle class="text-caption text-white">{{
                      chart.memberNick || chart.memberName
                    }}</v-card-subtitle>
                  </v-card>
                </v-row>
              </template>
              <span>{{ chart.memberName }}: {{ chart.points }} Pontos</span>
            </v-tooltip>
          </v-card>
        </template>
      </v-col>
      <v-col cols="12">
        <template>
          <v-card color="dark" v-if="hasData" class="dash-card">
            <BarChart :chartData="chartData" :options="options" :plugins="plugins" />
          </v-card>
        </template>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader type="image, article"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
import http from "@/services/http";
import moment from "moment";
import BarChart from "@/components/component/BarChart";
import PageHeader from "@/components/core/PageHeader.vue";
import PieChart from "@/components/component/PieChart";
import BaseMaterialStatsCard from "@/components/component/MaterialStatsCard.vue";
export default {
  name: "Ranking",
  components: { BarChart, PageHeader, PieChart, BaseMaterialStatsCard },
  data() {
    return {
      title: "Ranking",
      startDate: moment().startOf("month").format("yyyy-MM-DD"),
      endDate: moment().endOf("month").format("yyyy-MM-DD"),
      heightChartLine: 30,
      widthScreen: window.innerWidth,
      menu: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Ranking",
          disabled: true,
          href: "/",
        },
      ],
      hasData: false,
      images: [],
      topFive: [],
      companies: "",
      countMembers: 0,
      countCells: 0,
      countConversion: 0,
      countBaptism: 0,
      countInactiveMembers: 0,
      totalDebits: 0.0,
      totalCredits: 0.0,
      balance: 0.0,
      movements: [],
      chartMonth: [],
      chartData: {},
      chartResults: {},
      result: {
        payable: 0,
        receivable: 0,
        balance: 0,
      },
      plugins: [],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: "#9f9f9f",
                fontStyle: "bold",
                beginAtZero: true,
                display: true,
                min: 0,
                precision: 0,
                stepSize: 10,
              },
              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
        },
      },
      pieOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: "#9f9f9f",
                fontStyle: "bold",
                beginAtZero: true,
                display: false,
                min: 0,
                stepSize: 10,
              },
              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
        },
      },

      tabs: 0,
    };
  },
  methods: {
    complete(index) {
      this.list[index] = !this.list[index];
    },
    displayName(name) {
      if (!name) {
        return "?";
      }
      let display;
      const parts = name.trim().split(" ");
      if (parts.length > 1) {
        display = `${parts[0].substring(0, 1)}${parts[1].substring(0, 1)}`;
      } else {
        display = name.substring(0, 2);
      }
      if (!display) display = "?";
      return display.toUpperCase();
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    async fetchMovementsYear() {
      this.hasData = false;
      await http
        .get(
          `promise/presences/results/ranking?startDate=${this.startDate}&endDate=${this.endDate}`
        )
        .then(
          async (data) => {
            const result = data.data;

            this.movements = result.length > 5 ? result.slice(0, 5) : result;
            this.heightChartLine = 300 / this.movements.length;
            this.chartData = await {
              labels: data.data.map((d) => (d.memberNick ? d.memberNick : d.memberName)),
              datasets: [
                {
                  label: "Total de Pontos",
                  backgroundColor: data.data.map((d) =>
                    d.memberGender === "F" ? "#D81B60" : "#1976D2"
                  ),
                  data: data.data.map((d) => d.points),
                },
              ],
            };
            this.hasData = true;
            //  location.reload();
          },
          () => (this.hasData = true)
        );
    },
    async checkPermission() {
      let user = localStorage.getItem("user");
      if (user) user = JSON.parse(user);
      if (!user) this.$router.push("/login");
      if (user.admin) return;
      const permissions = user?.group?.permissions?.map((d) => d.id);

      if (permissions.includes("promise.presences.ranking")) return;
      else if (permissions.includes("promise.dashboard")) return;
      else if (permissions.includes("gerentor.person.customers.get_many")) {
        this.$router.push("/members");
      } else if (permissions.includes("promise.cells.get_many")) {
        this.$router.push("/groups");
      } else if (permissions.includes("promise.events.get_many")) {
        this.$router.push("/events");
      } else {
        localStorage.clear();
        this.$router.push("/login");
      }
    },
    async changeAll() {
      await this.checkPermission();
      await this.fetchMovementsYear();
    },
  },
  async mounted() {
    await this.changeAll();
  },
};
</script>
<style scoped>
.text-white {
  color: white !important;
}
.dash-card {
  padding-top: 5px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-bottom: 10px !important;
}
</style>
